@import url("./index.css");

.web * { 
	scrollbar-width: thin !important;
	scrollbar-color: var(--okx-scrollbar-color);
}

.web ::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.web ::-webkit-scrollbar-track {
	background: var(--okx-scrollbar-track-color);
	border-radius: 0;
}

.web ::-webkit-scrollbar-thumb {
	background: var(--okx-scrollbar-handle-color);
	border-radius: 0;
}

/* ion-app.ion-page.web {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

ion-app.ion-page.web.route-dashboard,
ion-app.ion-page.web.route-loyalty,
ion-app.ion-page.web.route-order-completed,
ion-app.ion-page.web.route-social {
	background-image: url(../assets/images/new-dashboard-01.jpg);
}


ion-app.ion-page.web.route-click-and-collect {
	background-image: url(../assets/images/clickAndCollect.jpg);
}


ion-app.ion-page.web.route-history-details,
ion-app.ion-page.web.route-history,
ion-app.ion-page.web.route-login,
ion-app.ion-page.web.route-register, 
ion-app.ion-page.web.route-reset-password {
	background-image: url(../assets/images/history-bg-web.jpg);
} */

.web .static ion-toolbar.primary-toolbar {
	--background: transparent none;
}

.web .mbsc-select-input {
	cursor: pointer;
}

.web .mbsc-select-input[disabled], .web .mbsc-sel-hdn[disabled] + .mbsc-select-input {
	cursor: default;
}

.web .static.route-register + .dynamic-header {
	display: none;
}

/*
.web #main > ion-header,
.web #main > ion-content {
	-webkit-animation: fadein 1s;
	-moz-animation: fadein 1s;
	-ms-animation: fadein 1s;
	-o-animation: fadein 1s;
	animation: fadein 1s;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
*/
@media screen and (min-width: 992px) {
	.web ion-header:not(.route-login) + ion-toolbar.dynamic-header,
	.web ion-header:not(.route-login) + .content-spinner + ion-toolbar.dynamic-header {
		top: -49px;
		width: 100px;
		z-index: 15;
	}
}
